<template>
  <v-card class="mb-4" flat>
    <validation-observer ref="obs" v-slot="{ invalid }">
      <v-card-text>
        <v-row>
          <v-col cols="4">
            <validation-provider
              vid="name"
              :name="$t('name')"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-text-field
                v-model="item.name"
                name="name"
                :label="$t('name')"
                :error-messages="errors"
                :class="classes"
                hide-details="auto"
                dense
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <validation-provider
              vid="dest_pattern"
              :name="$t('dest pattern')"
              rules="required"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-text-field
                v-model="item.dest_pattern"
                name="dest_pattern"
                :label="$t('dest pattern')"
                :error-messages="errors"
                :class="classes"
                hide-details="auto"
                dense
              />
            </validation-provider>
          </v-col>
          <v-col cols="4">
            <validation-provider
              vid="dest_replace"
              :name="$t('dest replace')"
              rules="required"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-text-field
                v-model="item.dest_replace"
                name="dest_replace"
                :label="$t('dest replace')"
                :error-messages="errors"
                :class="classes"
                hide-details="auto"
                dense
              />
            </validation-provider>
          </v-col>

          <v-col cols="4">
            <validation-provider
              vid="reject_pattern"
              :name="$t('reject pattern')"
              rules="required"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-text-field
                v-model="item.reject_pattern"
                name="reject_pattern"
                :label="$t('reject pattern')"
                :error-messages="errors"
                :class="classes"
                hide-details="auto"
                dense
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <validation-provider
              vid="source_pattern"
              :name="$t('source pattern')"
              rules="required"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-text-field
                v-model="item.source_pattern"
                name="source_pattern"
                :label="$t('source pattern')"
                :error-messages="errors"
                :class="classes"
                hide-details="auto"
                dense
              />
            </validation-provider>
          </v-col>

          <v-col cols="4">
            <validation-provider
              vid="source_presentation_pattern"
              :name="$t('source presentation pattern')"
              rules="required"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-text-field
                v-model="item.source_presentation_pattern"
                name="source_presentation_pattern"
                :label="$t('source presentation pattern')"
                :error-messages="errors"
                :class="classes"
                hide-details="auto"
                dense
              />
            </validation-provider>
          </v-col>

          <v-col cols="4">
            <validation-provider
              vid="time_pattern"
              :name="$t('time pattern')"
              rules="required"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-text-field
                v-model="item.time_pattern"
                name="time_pattern"
                :label="$t('time pattern')"
                :error-messages="errors"
                :class="classes"
                hide-details="auto"
                dense
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <validation-provider
              vid="disabled"
              :name="$t('disabled')"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-checkbox
                v-model="item.disabled"
                name="disabled"
                :label="$t('disabled')"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
                dense
              />
            </validation-provider>
          </v-col>
          <v-col cols="2">
            <validation-provider
              vid="always_ring"
              :name="$t('always ring')"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-checkbox
                v-model="item.always_ring"
                name="always_ring"
                :label="$t('always ring')"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
                dense
              />
            </validation-provider>
          </v-col>
          <v-col cols="2">
            <validation-provider
              vid="early_media"
              :name="$t('early media')"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-checkbox
                v-model="item.early_media"
                name="early_media"
                :label="$t('early media')"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
                dense
              />
            </validation-provider>
          </v-col>
          <v-col cols="2">
            <validation-provider
              vid="last_diversion"
              :name="$t('last diversion')"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-checkbox
                v-model="item.last_diversion"
                name="last_diversion"
                :label="$t('last diversion')"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
                dense
              />
            </validation-provider>
          </v-col>
          <v-col cols="2">
            <validation-provider
              vid="parallel_call"
              :name="$t('parallel call')"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-checkbox
                v-model="item.parallel_call"
                name="parallel_call"
                :label="$t('parallel call')"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
                dense
              />
            </validation-provider>
          </v-col>
          <v-col cols="2">
            <validation-provider
              vid="propagate_busy"
              :name="$t('propagate busy')"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-checkbox
                v-model="item.propagate_busy"
                name="propagate_busy"
                :label="$t('propagate busy')"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
                dense
              />
            </validation-provider>
          </v-col>
          <v-col cols="2">
            <validation-provider
              vid="reroute"
              :name="$t('reroute')"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-checkbox
                v-model="item.reroute"
                name="reroute"
                :label="$t('reroute')"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
                dense
              />
            </validation-provider>
          </v-col>
          <v-col cols="2">
            <validation-provider
              vid="suspended"
              :name="$t('suspended')"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-checkbox
                v-model="item.suspended"
                name="suspended"
                :label="$t('suspended')"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
                dense
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <validation-provider
              vid="start_date"
              :name="$t('start date')"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <date-picker 
                v-model="item.start_date" 
                name="start_date"
                :label="$t('start date')"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
              />
            </validation-provider>
          </v-col>
          <v-col cols="4">
            <validation-provider
              vid="end_date"
              :name="$t('end date')"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <date-picker 
                v-model="item.end_date" 
                name="end_date"
                :label="$t('end date')"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
              />
            </validation-provider>
          </v-col>
          <v-col cols="4">
            <validation-provider
              vid="type"
              :name="$t('type')"
              rules="required"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-select
                v-model="item.type"
                :items="types"
                item-text="text"
                item-value="value"
                name="type"
                :label="$t('type')"
                :error-messages="errors"
                :class="classes"
                single-line
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
           <v-col cols="4">
            <validation-provider
                vid="delay"
                :name="$t('delay')"
                v-slot="{ errors, valid, dirty, classes }"
              >
               <v-slider
                  v-model="item.delay"
                  name="delay"
                  :label="$t('delay')"
                  :error-messages="errors"
                  :class="classes"
                  thumb-color="primary"
                  thumb-label="always"
                  :thumb-size="20"
                  always-dirty
                  max="10"
                  min="0"
                >
                  <template v-slot:prepend>
                    <v-icon
                      @click="decrement('delay')"
                    >
                    mdi-minus
                  </v-icon>
                  </template>
                  <template v-slot:append>
                    <v-icon
                      @click="increment('delay')"
                    >
                      mdi-plus
                    </v-icon>
                  </template>
                </v-slider>
              </validation-provider>
           </v-col>
           <v-col cols="4">
            <validation-provider
                vid="priority"
                :name="$t('priority')"
                v-slot="{ errors, valid, dirty, classes }"
              >
               <v-slider
                  v-model="item.priority"
                  name="priority"
                  :label="$t('priority')"
                  :error-messages="errors"
                  :class="classes"
                  thumb-color="primary"
                  thumb-label="always"
                  :thumb-size="20"
                  always-dirty
                  max="10"
                  min="0"
                >
                  <template v-slot:prepend>
                    <v-icon
                      @click="decrement('priority')"
                    >
                    mdi-minus
                  </v-icon>
                  </template>
                  <template v-slot:append>
                    <v-icon
                      @click="increment('priority')"
                    >
                      mdi-plus
                    </v-icon>
                  </template>
                </v-slider>
              </validation-provider>
           </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn v-if="item.id" :disabled="loading"  @click="deleteItem" text>
          {{ $t("delete") }}
        </v-btn>
        <v-spacer />
        <v-btn :disabled="loading" @click="$emit('cancel')"" text>
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          :disabled="invalid || loading"
          :loading="loading"
          @click="item.id ? updateItem() : createItem()"
          text
        >
          {{ item.id ? $t("save") : $t("create")}}
        </v-btn>
      </v-card-actions>
    </validation-observer>
  </v-card>
</template>

<script>
import formatDate from "@/utils/mixins/formatDate";
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";
import BooleanValue from "@/components/basics/BooleanValue";
import DatePicker from "@/components/basics/DatePicker";

export default {
  name: "CallForward",
  mixins: [formatDate, showErrors, isMobile],
  components: { BooleanValue, DatePicker, },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    productId: {
      validator: (prop) => typeof prop === "string" || typeof prop === "number",
    },
    emptyCallForward: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data: () => ({
    item: null,
    loadingItem: false,
    updatingItem: false,
    creatingItem: false,
    deletingItem: false,
  }),
  computed: {
    loading() {
      return (
        this.loadingItem ||
        this.updatingItem ||
        this.creatingItem ||
        this.deletingItem
      );
    },
    types() {
      return [
        { text: this.$i18n.t("CFU"), value: "CFU" },
        { text: this.$i18n.t("CFF"), value: "CFF" },
        { text: this.$i18n.t("CFB"), value: "CFB" },
        { text: this.$i18n.t("CFR"), value: "CFR" },
        { text: this.$i18n.t("CFNR"), value: "CFNR" },
        { text: this.$i18n.t("CFO"), value: "CFO" },
        { text: this.$i18n.t("DIST"), value: "DIST" },
        { text: this.$i18n.t("REDIR"), value: "REDIR" },
      ];
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value) {
          this.item = { ...value };
        } else this.item = { ...emptyCallForward };
      },
    },
  },
  methods: {
    decrement(name) {
      this.item[name]--
    },
    increment(name) {
      this.item[name]++
    },
    getItem() {
      var that = this;
      that.loadingItem = true;
      this.$http
        .get(
          "services/voip/" + this.productId + "/call-forwards/" + this.value.id
        )
        .then((response) => {
          that.item = response.data;
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loadingItem = false;
        });
    },
    updateItem() {
      var that = this;
      this.updatingItem = true;
      this.$http
        .patch(
          "services/voip/" + this.productId + "/call-forwards/" + this.item.id,
          { ...this.item }
        )
        .then((response) => {
          that.item = response.data;
          that.$emit("update", that.item);
        })
        .catch((error) => {
          that.$store.commit("setSystemError", {
            msg: error.message,
            title: error.title,
          });
        })
        .finally(function () {
          that.updatingItem = false;
        });
    },
    createItem() {
      var that = this;
      this.updatingItem = true;
      this.$http
        .post("services/voip/" + this.productId + "/call-forwards", {
          ...this.item,
        })
        .then((response) => {
          that.item = response.data;
          that.$emit("created", that.item);
        })
        .catch((error) => {
          that.$store.commit("setSystemError", {
            msg: error.message,
            title: error.title,
          });
        })
        .finally(function () {
          that.creatingItem = false;
        });
    },
    deleteItem() {
      var that = this;
      this.deletingItem = true;
      this.$http
        .delete(
          "services/voip/" + this.productId + "/call-forwards/" + this.item.id
        )
        .then((response) => {
          that.item = null;
          that.$emit("deleted");
        })
        .catch((error) => {
          that.$store.commit("setSystemError", {
            msg: error.message,
            title: error.title,
          });
        })
        .finally(function () {
          that.deleteItem = false;
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "name": "Name",
    "type": "Type",
    "priority": "Priority",
    "dest pattern": "Dest pattern",
    "source pattern": "Source pattern",
    "source presentation pattern": "Source presentation pattern",
    "dest replace": "Dest replace",
    "time pattern": "Time pattern",
    "reject pattern": "Reject pattern",
    "delay": "Delay",
    "propagate busy": "Propagate busy",
    "parallel call": "Parallel call",
    "always ring": "Always ring",
    "early media": "Early media",
    "last diversion": "Last diversion",
    "reroute": "Reroute",
    "suspended": "Suspended",
    "disabled": "Disabled",
    "start date": "Start date",
    "end date": "End date",
    "delay": "Delay",
    "CFU": "CFU",
    "CFF": "CFF",
    "CFB": "CFB",
    "CFR": "CFR",
    "CFNR": "CFNR",
    "CFO": "CFO",
    "DIST": "DIST",
    "REDIR": "REDIR",
    "cancel": "Cancel",
    "save": "Save",
    "create": "Create",
    "delete": "Delete"
  },
  "de": {
    "name": "Name",
    "type": "Typ",
    "priority": "Priorität",
    "dest pattern": "Dest Pattern",
    "source pattern": "Source Pattern",
    "source presentation pattern": "Source Presentation Pattern",
    "dest replace": "Dest Replace",
    "time pattern": "Time Pattern",
    "reject pattern": "Reject Pattern",
    "delay": "Verzögerung",
    "propagate busy": "Propagiere beschäftigt",
    "parallel call": "Paralleler Anruf",
    "always ring": "Immer klingeln",
    "early media": "Early Media",
    "last diversion": "Letzte Umleitung",
    "reroute": "Umleiten",
    "suspended": "Ausgesetzt",
    "disabled": "Ausgeschaltet",
    "start date": "Startdatum",
    "end date": "Enddatum",
    "delay": "Verzögerung",
    "CFU": "CFU",
    "CFF": "CFF",
    "CFB": "CFB",
    "CFR": "CFR",
    "CFNR": "CFNR",
    "CFO": "CFO",
    "DIST": "DIST",
    "REDIR": "REDIR",
    "cancel": "Abbruch",
    "save": "Speichern",
    "create": "Anlegen",
    "delete": "Löschen"
  }
}
</i18n>
