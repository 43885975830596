<template>
  <v-card class="mb-4" :loading="loading" outlined>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>{{ $t("cdrs") }}</v-toolbar-title>
      <v-spacer />

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <date-picker
            v-on="on"
            v-model="dates"
            :allowedDates="dateAllowed"
            icon
            range
            sort-range
            left
            bottom
            rounded
          />
        </template>
        <span>{{ $t("query period") }}</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            :disabled="loading"
            :loading="loading"
            v-on="on"
            @click="getItems()"
            icon
          >
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("reload") }}</span>
      </v-tooltip>
    </v-toolbar>

    <v-card-text>
      <data-table-extended
        :headers="headers"
        :items="items"
        :loading="loading"
        :mobile-breakpoint="mbreakpoint"
        :search="search"
        :custom-filter="customFilter"
        sort-by="call_start_datetime"
        sort-desc
        :hide-default-footer="items.length <= 15"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [15, 50, 100, 500, 1000],
        }"
        show-menu
        local-storage-name="voipCdrList"
      >
        <template v-slot:top>
          <v-text-field
            class="mb-4"
            v-model="search"
            append-outer-icon="mdi-magnify"
            :clearable="!isMobile"
            v-bind:label="$t('search')"
            single-line
            hide-details
            clearable
          />
        </template>
        <template v-slot:item.direction="{ item }">
          <v-icon v-if="(item.direction = 'incoming')"
            >mdi-phone-incoming</v-icon
          >
          <v-icon v-else>mdi-phone-outgoing</v-icon>
        </template>
        <template v-slot:item.call_start_datetime="{ item }">
          {{
            item.call_start_datetime
              ? formatDateTime(item.call_start_datetime)
              : "-"
          }}
        </template>
        <template v-slot:item.call_end_datetime="{ item }">
          {{
            item.call_end_datetime
              ? formatDateTime(item.call_end_datetime)
              : "-"
          }}
        </template>
        <template v-slot:item.duration="{ item }">
          {{
            item.call_start_datetime && item.call_end_datetime
              ? formatDuration(
                  (Date.parse(item.call_end_datetime) -
                    Date.parse(item.call_start_datetime)) /
                    1000
                )
              : "-"
          }}
        </template>
        <template v-slot:item.costs="{ item }">
          {{ item.cost ? formatMoney(item.cost / 100000, "CHF") : "-" }}
        </template>
      </data-table-extended>
    </v-card-text>
  </v-card>
</template>

<script>
import formatDateTime from "@/utils/mixins/formatDateTime";
import formatMoney from "@/utils/mixins/formatMoney";
import formatNumber from "@/utils/mixins/formatNumber";
import formatDuration from "@/utils/mixins/formatDuration";
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";
import DataTableExtended from "@/components/basics/DataTableExtended";
import BooleanValue from "@/components/basics/BooleanValue";
import DatePicker from "@/components/basics/DatePicker";

export default {
  name: "Cdrs",
  mixins: [
    formatDateTime,
    formatMoney,
    formatNumber,
    formatDuration,
    showErrors,
    isMobile,
  ],
  components: {
    DataTableExtended,
    BooleanValue,
    DatePicker,
  },
  props: {
    productId: {
      validator: (prop) => typeof prop === "string" || typeof prop === "number",
    },
  },
  data() {
    return {
      items: [],
      loading: false,
      dates: [
        this.$moment(new Date()).subtract(30, "days").format("YYYY-MM-DD"),
        this.$moment(new Date()).format("YYYY-MM-DD"),
      ],
      search: "",
    };
  },
  watch: {
    productId: {
      immediate: true,
      handler(value) {
        this.getItems();
      },
    },
    dates(value) {
      if (value && value.length == 2) {
        this.getItems();
      }
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$i18n.t("direction"),
          value: "direction",
          align: "left",
        },
        {
          text: this.$i18n.t("call start"),
          value: "call_start_datetime",
          align: "right",
        },
        {
          text: this.$i18n.t("call end"),
          value: "call_end_datetime",
          align: "right",
        },
        {
          text: this.$i18n.t("duration"),
          value: "duration",
          align: "left",
        },
        {
          text: this.$i18n.t("called number"),
          value: "destination_number",
          align: "left",
        },
        {
          text: this.$i18n.t("calling number"),
          value: "source_number",
          align: "left",
        },
        {
          text: this.$i18n.t("costs"),
          value: "costs",
          align: "right",
        },
      ];
    },
  },
  methods: {
    dateAllowed(date) {
      const today = this.$moment(new Date()).format("YYYY-MM-DD");
      return (
        this.$moment(today).isSame(date, "day") ||
        this.$moment(date).isBefore(today)
      );
    },
    customFilter(value, search, item) {
      return (
        (item.destination_number != null &&
          item.destination_number.indexOf(search) >= 0) ||
        (item.source_number != null && item.source_number.indexOf(search) >= 0)
      );
    },
    getItems(number) {
      var that = this;
      var params = {};
      params["call_date_after"] =
        this.dates.length > 0
          ? this.$moment(this.dates[0]).format("YYYY-MM-DDT00:00:00Z")
          : null;
      params["call_date_before"] =
        this.dates.length > 1
          ? this.$moment(this.dates[1]).format("YYYY-MM-DDT23:59:59Z")
          : null;
      this.loading = true;
      this.$http
        .get("services/voip/" + this.productId + "/cdrs", { params: params })
        .then((response) => {
          that.items = response.data.map(function (cdr) {
            return {
              ...cdr,
              product_id: that.productId,
            };
          });
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "cdrs": "Connection details",
    "reload": "reload",
    "direction": "Direction",
    "call start": "Call start",
    "call end": "Call end",
    "duration": "Duration",
    "called number": "Called number",
    "calling number": "Calling number",
    "costs": "Costs",
    "query period": "Query period",
    "search": "Search"
  },
  "de": {
    "cdrs": "Verbindungsdetails",
    "reload": "aktualisieren",
    "direction": "Richtung",
    "call start": "Anrufstart",
    "call end": "Anrufende",
    "duration": "Länge",
    "called number": "Zielnummer",
    "calling number": "Anrufernummer",
    "costs": "Costs",
    "query period": "Abfragezeitraum",
    "search": "Suche"
  }
}
</i18n>
