<template>
  <v-card class="mb-4" :loading="loadingItems" outlined>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>{{ $t("voice mails") }}</v-toolbar-title>
      <v-spacer />

      <v-menu left bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-phone-settings-outline</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item
            v-if="currentNumber"
            active-class="primary primaryText--text"
            @click="currentNumber = null"
          >
            <v-list-item-icon>
              <v-icon>mdi-undo</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t("show voice mails") }}
            </v-list-item-title>
          </v-list-item>

          <template v-for="(number, key) in numbers">
            <v-list-item
              v-if="currentNumber == null || currentNumber.id != number.id"
              active-class="primary primaryText--text"
              @click="currentNumber = number"
            >
              <v-list-item-icon>
                <v-icon>mdi-phone-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ number.national_number }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            :disabled="loading"
            :loading="loading"
            v-on="on"
            @click="
              currentNumber = null;
              getAllItems();
            "
            icon
          >
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("reload") }}</span>
      </v-tooltip>
    </v-toolbar>

    <div v-if="showConfig">
      <validation-observer ref="obsConfig" v-slot="{ invalid, valid }">
        <v-card-title>
          {{ $t("voice mail settings for") }}
          {{ currentNumber.national_number }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <validation-provider
                vid="config.active"
                :name="$t('active')"
                v-slot="{ errors, valid, dirty, classes }"
              >
                <v-switch
                  v-model="config.active"
                  name="config.active"
                  :label="$t('active')"
                  :error-messages="errors"
                  :class="classes"
                  dense
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <validation-provider
                vid="config.auto_authentication"
                :name="$t('auto authentication')"
                v-slot="{ errors, valid, dirty, classes }"
              >
                <v-checkbox
                  v-model="config.auto_authentication"
                  name="config.auto_authentication"
                  :label="$t('auto authentication')"
                  :error-messages="errors"
                  :class="classes"
                  :disabled="!config.active"
                  dense
                />
              </validation-provider>
            </v-col>

            <v-col cols="3">
              <validation-provider
                vid="config.skip_message_menu"
                :name="$t('skip message menu')"
                v-slot="{ errors, valid, dirty, classes }"
              >
                <v-checkbox
                  v-model="config.skip_message_menu"
                  name="config.skip_message_menu"
                  :label="$t('skip message menu')"
                  :error-messages="errors"
                  :class="classes"
                  :disabled="!config.active"
                  dense
                />
              </validation-provider>
            </v-col>
            <v-col cols="3">
              <validation-provider
                vid="config.signal_new_message"
                :name="$t('signal new message')"
                v-slot="{ errors, valid, dirty, classes }"
              >
                <v-checkbox
                  v-model="config.signal_new_message"
                  name="config.signal_new_message"
                  :label="$t('signal new message')"
                  :error-messages="errors"
                  :class="classes"
                  :disabled="!config.active"
                  dense
                />
              </validation-provider>
            </v-col>
            <v-col cols="3">
              <validation-provider
                vid="config.subscribed_mwi_only"
                :name="$t('subscribed mwi only')"
                v-slot="{ errors, valid, dirty, classes }"
              >
                <v-checkbox
                  v-model="config.subscribed_mwi_only"
                  name="config.subscribed_mwi_only"
                  :label="$t('subscribed mwi only')"
                  :error-messages="errors"
                  :class="classes"
                  :disabled="!config.active"
                  dense
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <validation-provider
                vid="config.format_mp3"
                :name="$t('format mp3')"
                v-slot="{ errors, valid, dirty, classes }"
              >
                <v-checkbox
                  v-model="config.format_mp3"
                  name="config.format_mp3"
                  :label="$t('format mp3')"
                  :error-messages="errors"
                  :class="classes"
                  :disabled="!config.active"
                  dense
                />
              </validation-provider>
            </v-col>
            <v-col cols="3">
              <validation-provider
                vid="config.delete_sent"
                :name="$t('delete sent')"
                v-slot="{ errors, valid, dirty, classes }"
              >
                <v-checkbox
                  v-model="config.delete_sent"
                  name="config.delete_sent"
                  :label="$t('delete sent')"
                  :error-messages="errors"
                  :class="classes"
                  :disabled="!config.active"
                  dense
                />
              </validation-provider>
            </v-col>
            <v-col cols="3">
              <validation-provider
                vid="config.email_without_message"
                :name="$t('email without message')"
                v-slot="{ errors, valid, dirty, classes }"
              >
                <v-checkbox
                  v-model="config.email_without_message"
                  name="config.email_without_message"
                  :label="$t('email without message')"
                  :error-messages="errors"
                  :class="classes"
                  :disabled="!config.active"
                  dense
                />
              </validation-provider>
            </v-col>
            <v-col cols="3">
              <validation-provider
                vid="config.blocked"
                :name="$t('blocked')"
                v-slot="{ errors, valid, dirty, classes }"
              >
                <v-checkbox
                  v-model="config.blocked"
                  name="config.blocked"
                  :label="$t('blocked')"
                  :error-messages="errors"
                  :class="classes"
                  :disabled="!config.active"
                  dense
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <validation-provider
                vid="config.fax_auto_detect"
                :name="$t('fax auto detect')"
                v-slot="{ errors, valid, dirty, classes }"
              >
                <v-checkbox
                  v-model="config.fax_auto_detect"
                  name="config.fax_auto_detect"
                  :label="$t('fax auto detect')"
                  :error-messages="errors"
                  :class="classes"
                  :disabled="!config.active"
                  dense
                />
              </validation-provider>
            </v-col>
            <v-col cols="3">
              <validation-provider
                vid="config.fax_only"
                :name="$t('fax only')"
                v-slot="{ errors, valid, dirty, classes }"
              >
                <v-checkbox
                  v-model="config.fax_only"
                  name="config.fax_only"
                  :label="$t('fax only')"
                  :error-messages="errors"
                  :class="classes"
                  :disabled="!config.active"
                  dense
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <validation-provider
                vid="config.language"
                :name="$t('language')"
                rules="required"
                v-slot="{ errors, valid, dirty, classes }"
              >
                <v-select
                  v-model="config.language"
                  :items="languages"
                  item-text="text"
                  item-value="iso"
                  name="config.language"
                  :label="$t('language')"
                  :error-messages="errors"
                  :class="classes"
                  :disabled="!config.active"
                  single-line
                  dense
                />
              </validation-provider>
            </v-col>
            <v-col cols="4">
              <validation-provider
                vid="config.email"
                :name="$t('email')"
                rules="email"
                v-slot="{ errors, valid, dirty, classes }"
              >
                <v-text-field
                  v-model="config.email"
                  name="config.email"
                  :label="$t('email')"
                  :error-messages="errors"
                  :class="classes"
                  :disabled="!config.active"
                  hide-details="auto"
                  dense
                />
              </validation-provider>
            </v-col>
            <v-col cols="4">
              <validation-provider
                vid="config.password"
                :name="$t('password')"
                rules="numeric|max:8|min:4"
                v-slot="{ errors, valid, dirty, classes }"
              >
                <v-text-field
                  v-model="config.password"
                  name="config.password"
                  :label="$t('password')"
                  :error-messages="errors"
                  :class="classes"
                  :disabled="!config.active"
                  hide-details="auto"
                  dense
                  type="password"
                  minlength="4"
                  maxlength="8"
                />
              </validation-provider>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-title>
          {{ $t("voice announcement settings for") }}
          {{ currentNumber.national_number }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <validation-provider
                vid="announcement.saved"
                :name="$t('saved')"
                v-slot="{ errors, valid, dirty, classes }"
              >
                <v-checkbox
                  v-model="announcement.saved"
                  name="announcement.saved"
                  :label="$t('saved')"
                  :error-messages="errors"
                  :class="classes"
                  dense
                />
              </validation-provider>
            </v-col>
            <v-col cols="3">
              <validation-provider
                vid="announcement.static"
                :name="$t('static')"
                v-slot="{ errors, valid, dirty, classes }"
              >
                <v-checkbox
                  v-model="announcement.static"
                  name="announcement.static"
                  :label="$t('static')"
                  :error-messages="errors"
                  :class="classes"
                  dense
                />
              </validation-provider>
            </v-col>
            <v-col cols="3">
              <validation-provider
                vid="announcement.music_on_hold"
                :name="$t('music on hold')"
                v-slot="{ errors, valid, dirty, classes }"
              >
                <v-checkbox
                  v-model="announcement.music_on_hold"
                  name="announcement.music_on_hold"
                  :label="$t('music on hold')"
                  :error-messages="errors"
                  :class="classes"
                  dense
                />
              </validation-provider>
            </v-col>
            <v-col cols="3">
              <validation-provider
                vid="announcement.unlocked"
                :name="$t('unlocked')"
                v-slot="{ errors, valid, dirty, classes }"
              >
                <v-checkbox
                  v-model="announcement.unlocked"
                  name="announcement.unlocked"
                  :label="$t('unlocked')"
                  :error-messages="errors"
                  :class="classes"
                  dense
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <validation-provider
                vid="announcement.deleted"
                :name="$t('deleted')"
                v-slot="{ errors, valid, dirty, classes }"
              >
                <v-checkbox
                  v-model="announcement.deleted"
                  name="announcement.deleted"
                  :label="$t('deleted')"
                  :error-messages="errors"
                  :class="classes"
                  dense
                />
              </validation-provider>
            </v-col>
            <v-col cols="6">
              <validation-provider
                vid="audio"
                :name="$t('announcement upload')"
                v-slot="{ errors, valid, dirty, classes }"
              >
                <v-file-input
                  id="audioUpload"
                  v-model="audioUpload"
                  truncate-length="30"
                  accept="audio/*"
                  name="audio"
                  :label="$t('audio upload')"
                  :error-messages="errors"
                  :success="dirty && valid"
                  :class="classes"
                  clearable
                  show-size
                />
              </validation-provider>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="currentNumber = null"
            :disabled="updatingConfig || uploadingAudio"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            :disabled="invalid || updatingConfig || uploadingAudio"
            :loading="updatingConfig || uploadingAudio"
            @click="updateConfig"
            text
          >
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </div>
    <v-card-text v-if="!showConfig">
      <data-table-extended
        :headers="headers"
        :items="items"
        item-key="id"
        disable-sort
        :expanded="expanded"
        show-expand
        disable-pagination
        hide-default-footer
      >
        <template v-slot:item.name="{ item }">
          {{ item.name ? item.name : "-" }}
        </template>
        <template v-slot:item.number="{ item }">
          {{ item.number ? item.number : "-" }}
        </template>
        <template v-slot:item.time_start="{ item }">
          {{ item.time_start ? formatDateTime(item.time_start) : "-" }}
        </template>
        <template v-slot:item.time_played="{ item }">
          {{ item.time_played ? formatDateTime(item.time_played) : "-" }}
        </template>
        <template v-slot:item.duration="{ item }">
          {{ item.duration ? formatDuration(item.duration / 1000) : "-" }}
        </template>
        <template v-slot:item.saved="{ item }">
          <boolean-value v-model="item.saved" />
        </template>
        <template v-slot:item.static="{ item }">
          <boolean-value v-model="item.static" />
        </template>
        <template v-slot:item.greeting="{ item }">
          <boolean-value v-model="item.greeting" />
        </template>
        <template v-slot:item.music_on_hold="{ item }">
          <boolean-value v-model="item.music_on_hold" />
        </template>
        <template v-slot:item.unlocked="{ item }">
          <boolean-value v-model="item.unlocked" />
        </template>
        <template v-slot:item.deleted="{ item }">
          <boolean-value v-model="item.deleted" />
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="py-2">
            <voice-mail v-model="item" @update="getAllItems()" />
          </td>
        </template>
      </data-table-extended>
    </v-card-text>
  </v-card>
</template>

<script>
import formatDateTime from "@/utils/mixins/formatDateTime";
import formatMoney from "@/utils/mixins/formatMoney";
import formatNumber from "@/utils/mixins/formatNumber";
import formatDuration from "@/utils/mixins/formatDuration";
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";
import DataTableExtended from "@/components/basics/DataTableExtended";
import BooleanValue from "@/components/basics/BooleanValue";
import VoiceMail from "@/components/services/voip/VoiceMail";

const emptyConfig = {
  active: true,
  auto_authentication: true,
  skip_message_menu: false,
  language: null,
  signal_new_message: true,
  subscribed_mwi_only: false,
  password: null,
  email: null,
  format_mp3: false,
  delete_sent: false,
  email_without_message: false,
  blocked: false,
  fax_only: false,
  fax_auto_detect: true,
};

const emptyAnnouncement = {
  name: null,
  saved: false,
  static: false,
  music_on_hold: false,
  unlocked: false,
  deleted: false,
};

export default {
  name: "VoiceMails",
  mixins: [
    formatDateTime,
    formatMoney,
    formatNumber,
    formatDuration,
    showErrors,
    isMobile,
  ],
  components: {
    DataTableExtended,
    VoiceMail,
    BooleanValue,
  },
  props: {
    numbers: {
      type: Array,
      default: () => [],
    },
    productId: {
      validator: (prop) => typeof prop === "string" || typeof prop === "number",
    },
  },
  data: () => ({
    items: [],
    expanded: [],
    loadingItemsCount: 0,
    currentNumber: null,
    config: { ...emptyConfig },
    announcement: { ...emptyAnnouncement },
    updatingConfig: false,
    uploadingAudio: false,
    audioUpload: null,
    uploadAudio: false,
  }),
  watch: {
    numbers: {
      immediate: true,
      handler(value) {
        this.getAllItems();
      },
    },
    currentNumber(value) {
      if (value && value.config) this.config = { ...value.config };
      else this.config = { ...emptyConfig };
    },
    latestAnnouncement(value) {
      if (value) this.announcement = { ...value };
    },
  },
  computed: {
    showConfig() {
      return this.currentNumber != null;
    },
    loading() {
      return this.loadingItems || this.updatingConfig || this.uploadingAudio;
    },
    loadingItems() {
      return this.numbers.length != this.loadingItemsCount;
    },
    languages() {
      return [
        {
          text: this.$i18n.t("german"),
          iso: "de",
        },
        {
          text: this.$i18n.t("english"),
          iso: "en",
        },
        {
          text: this.$i18n.t("italian"),
          iso: "it",
        },
        {
          text: this.$i18n.t("french"),
          iso: "fr",
        },
      ];
    },
    headers() {
      return [
        {
          text: this.$i18n.t("name"),
          value: "name",
          align: "left",
        },
        {
          text: this.$i18n.t("called number"),
          align: "right",
          value: "destination_number",
        },
        {
          text: this.$i18n.t("calling number"),
          align: "right",
          value: "number",
        },
        {
          text: this.$i18n.t("time start"),
          align: "right",
          value: "time_start",
        },
        {
          text: this.$i18n.t("time played"),
          align: "right",
          value: "time_played",
        },
        {
          text: this.$i18n.t("duration"),
          align: "right",
          value: "duration",
        },
        {
          text: this.$i18n.t("greeting"),
          align: "right",
          value: "greeting",
        },
        /*
        {
          text: this.$i18n.t("saved"),
          align: "right",
          value: "saved",
        },
        {
          text: this.$i18n.t("static"),
          align: "right",
          value: "static",
        },
        {
          text: this.$i18n.t("music on hold"),
          align: "right",
          value: "music_on_hold",
        },
        {
          text: this.$i18n.t("unlocked"),
          align: "right",
          value: "unlocked",
        },
        */
        { text: "", value: "data-table-expand" },
      ];
    },
    latestAnnouncement() {
      const announcements = this.items
        .filter((vm) => vm.greeting)
        .sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0));
      if (announcements.length > 0) return announcements[0];
    },
  },
  methods: {
    getAllItems() {
      if (this.numbers) {
        var that = this;
        this.items = [];
        this.loadingItemsCount = 0;
        this.numbers.forEach(function (number) {
          that.getItems(number);
        });
      }
    },
    getItems(number) {
      var that = this;
      this.$http
        .get(
          "services/voip/" +
            this.productId +
            "/numbers/" +
            number.id +
            "/voice-mails"
        )
        .then((response) => {
          that.items = [
            ...that.items,
            ...response.data.map(function (vm) {
              return {
                ...vm,
                product_id: that.productId,
                number_id: number.id,
                destination_number: number.national_number,
              };
            }),
          ];
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loadingItemsCount = that.loadingItemsCount + 1;
        });
    },
    updateConfig() {
      var that = this;
      this.updatingConfig = true;
      this.$http
        .patch(
          "services/voip/" +
            this.productId +
            "/numbers/" +
            this.currentNumber.id,
          {
            config: {
              active: this.config.active || false,
              auto_authentication: this.config.auto_authentication || false,
              skip_message_menu: this.config.skip_message_menu || false,
              language: this.config.language,
              signal_new_message: this.config.signal_new_message || false,
              subscribed_mwi_only: this.config.subscribed_mwi_only || false,
              password: this.config.password || null,
              email: this.config.email || null,
              format_mp3: this.config.format_mp3 || false,
              delete_sent: this.config.delete_sent || false,
              email_without_message: this.config.email_without_message || false,
              blocked: this.config.blocked || false,
              fax_only: this.config.fax_only || false,
              fax_auto_detect: this.config.fax_auto_detect || false,
            },
            announcement: {
              saved: this.announcement.saved || false,
              static: this.announcement.static || false,
              music_on_hold: this.announcement.music_on_hold || false,
              unlocked: this.announcement.unlocked || false,
              deleted: this.announcement.deleted || false,
            },
          }
        )
        .then((response) => {
          that.$emit("updated");
          if (this.audioUpload) {
            that.uploadingAudio = true;
            const formData = new FormData();
            formData.append("audio", this.audioUpload);
            this.$http
              .patch(
                "services/voip/" +
                  this.productId +
                  "/numbers/" +
                  this.currentNumber.id,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              )
              .then((response) => {})
              .catch((err) => {
                that.$store.commit("setSystemError", {
                  msg: err.message,
                  title: err.title,
                });
              })
              .finally(function () {
                that.uploadingAudio = false;
              });
          }
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.updatingConfig = false;
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "voice mails": "Voice Mails",
    "name": "Name",
    "number": "Number",
    "time start": "Time start",
    "time played": "Time played",
    "duration": "Duration",
    "saved": "Saved",
    "static": "Static",
    "greeting": "Greeting",
    "music on hold": "Music on hold",
    "unlocked": "Unlocked",
    "deleted": "Deleted",
    "reload": "reload",
    "show voice mails": "show voice mails",
    "active": "Active",
    "auto authentication": "Auto authentication",
    "skip message menu": "Skip message menu",
    "signal new message": "Signal new messages",
    "subscribed mwi only": "Subscribed MWI only",
    "format mp3": "Format MP3",
    "delete sent": "Delete sent",
    "email without message": "Email without message",
    "blocked": "Blocked",
    "language": "Language",
    "email": "Email",
    "password": "PIN",
    "fax auto detect": "Fax auto detect",
    "fax only": "Fax only",
    "voice announcement settings for": "Voice announcement settings for",
    "voice mail settings for": "Voice mail settings for",
    "saved": "Saved",
    "static": "Static",
    "deleted": "Deleted",
    "announcement upload": "Announcement upload",
    "audio upload": "Audio upload",
    "called number": "Called number",
    "calling number": "Calling number"
  },
  "de": {
    "voice mails": "Sprachnachrichen",
    "name": "Name",
    "number": "Number",
    "time start": "Time start",
    "time played": "Time played",
    "duration": "Duration",
    "saved": "Saved",
    "static": "Static",
    "greeting": "Greeting",
    "music on hold": "Music on hold",
    "unlocked": "Unlocked",
    "deleted": "Deleted",
    "reload": "aktualisieren",
    "show voice mails": "Sprachnachrichen anzeigen",
    "active": "aktivieren",
    "auto authentication": "Auto authentication",
    "skip message menu": "Skip message menu",
    "signal new message": "Signal new messages",
    "subscribed mwi only": "Subscribed MWI only",
    "format mp3": "Format MP3",
    "delete sent": "Delete sent",
    "email without message": "Email without message",
    "blocked": "Blocked",
    "language": "Sprache",
    "email": "Email",
    "password": "PIN",
    "fax auto detect": "Fax auto detect",
    "fax only": "Fax only",
    "voice announcement settings for": "Sprachansage Einstellungen für",
    "voice mail settings for": "Sprachnachrichen Einstellungen für",
    "saved": "Saved",
    "static": "Static",
    "deleted": "Deleted",
    "announcement upload": "Announcement upload",
    "audio upload": "Audio upload",
    "called number": "Angerufende Nummer",
    "calling number": "Anrufernummer"
  }
}
</i18n>
