<template>
  <v-card class="mb-4" :loading="loading" outlined>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>{{ $t("call forwards") }}</v-toolbar-title>
      <v-spacer />
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            :disabled="loading"
            :loading="loading"
            v-on="on"
            @click="
              expanded = [];
              showAddCallForward = !showAddCallForward;
            "
            icon
          >
            <v-icon>mdi-playlist-plus</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("add new call forward") }}</span>
      </v-tooltip>
    </v-toolbar>

    <v-card-text>
      <call-forward
        v-if="showAddCallForward"
        v-model="newItem"
        :product-id="productId"
        :empty-call-forward="emptyCallForward"
        @cancel="
          showAddCallForward = false;
          newItem = { ...emptyCallForward };
        "
        @created="
          getItems();
          expanded = [];
          showAddCallForward = false;
          newItem = { ...emptyCallForward };
        "
      />
      <data-table-extended
        v-else
        :headers="headers"
        :items="items"
        :loading="loading"
        :mobile-breakpoint="mbreakpoint"
        sort-by="id"
        sort-desc
        :expanded="expanded"
        show-expand
        :hide-default-footer="items.length <= 15"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [15, 50],
        }"
      >
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="py-2">
            <call-forward
              v-model="item"
              :product-id="productId"
              :empty-call-forward="emptyCallForward"
              @cancel="expanded = []"
              @deleted="
                getItems();
                expanded = [];
              "
              @update="getItems()"
            />
          </td>
        </template>
      </data-table-extended>
    </v-card-text>
  </v-card>
</template>

<script>
import formatNumber from "@/utils/mixins/formatNumber";
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";
import DataTableExtended from "@/components/basics/DataTableExtended";
import BooleanValue from "@/components/basics/BooleanValue";
import CallForward from "@/components/services/voip/CallForward";

export default {
  name: "CallForwards",
  mixins: [formatNumber, showErrors, isMobile],
  components: {
    DataTableExtended,
    BooleanValue,
    CallForward,
  },
  props: {
    productId: {
      validator: (prop) => typeof prop === "string" || typeof prop === "number",
    },
  },
  data() {
    return {
      items: [],
      expanded: [],
      loading: false,
      showAddCallForward: false,
      newItem: { ...this.emptyCallForward },
    };
  },
  watch: {
    productId: {
      immediate: true,
      handler(value) {
        this.getItems();
      },
    },
  },
  computed: {
    emptyCallForward() {
      return {
        id: null,
        name: null,
        type: "CFU",
        priority: 1,
        dest_pattern: null,
        source_pattern: null,
        source_presentation_pattern: null,
        dest_replace: null,
        time_pattern: null,
        reject_pattern: null,
        delay: 3,
        propagate_busy: false,
        parallel_call: false,
        always_ring: false,
        early_media: false,
        last_diversion: false,
        reroute: false,
        suspended: false,
        disabled: false,
        start_date: null,
        end_date: null,
      };
    },
    headers() {
      return [
        {
          text: this.$i18n.t("name"),
          value: "name",
        },
        {
          text: this.$i18n.t("delay"),
          value: "delay",
        },
        {
          text: this.$i18n.t("dest pattern"),
          value: "dest_pattern",
        },
        {
          text: this.$i18n.t("dest replace"),
          value: "dest_replace",
        },
        {
          text: this.$i18n.t("source pattern"),
          value: "source_pattern",
        },
        {
          text: this.$i18n.t("source presentation pattern"),
          value: "source_presentation_pattern",
        },
        { text: "", value: "data-table-expand" },
      ];
    },
  },
  methods: {
    getItems() {
      var that = this;
      this.loading = true;
      this.$http
        .get("services/voip/" + this.productId + "/call-forwards")
        .then((response) => {
          that.items = response.data.map(function (cdr) {
            return {
              ...cdr,
              product_id: that.productId,
            };
          });
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "call forwards": "Call forwards",
    "name": "Name",
    "delay": "delay",
    "dest pattern": "dest pattern",
    "dest replace": "dest replace",
    "source pattern": "source pattern",
    "source presentation pattern": "source presentation pattern",
    "add new call forward": "add new call forward"
  },
  "de": {
    "call forwards": "Anrufweiterleitungen",
    "name": "Name",
    "delay": "Verzögerung",
    "dest pattern": "dest pattern",
    "dest replace": "dest replace",
    "source pattern": "source pattern",
    "source presentation pattern": "source presentation pattern",
    "add new call forward": "neue Anrufweiterleitung erstellen"
  }
}
</i18n>
