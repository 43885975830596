<template>
  <v-card class="mb-4" flat>
    <validation-observer ref="obs" v-slot="{ invalid }">
      <v-card-text>
        <v-row v-if="!loading">
          <v-col cols="2">
            <validation-provider
              vid="saved"
              :name="$t('saved')"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-checkbox
                v-model="item.saved"
                name="saved"
                :label="$t('saved')"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
                dense
              />
            </validation-provider>
          </v-col>
          <v-col cols="2">
            <validation-provider
              vid="static"
              :name="$t('static')"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-checkbox
                v-model="item.static"
                name="static"
                :label="$t('static')"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
                dense
              />
            </validation-provider>
          </v-col>
          <v-col cols="2">
            <validation-provider
              vid="greeting"
              :name="$t('greeting')"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-checkbox
                v-model="item.greeting"
                name="greeting"
                :label="$t('greeting')"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
                dense
                disabled
              />
            </validation-provider>
          </v-col>
          <v-col cols="2">
            <validation-provider
              vid="music_on_hold"
              :name="$t('music on hold')"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-checkbox
                v-model="item.music_on_hold"
                name="music_on_hold"
                :label="$t('music on hold')"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
                dense
              />
            </validation-provider>
          </v-col>
          <v-col cols="2">
            <validation-provider
              vid="unlocked"
              :name="$t('unlocked')"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-checkbox
                v-model="item.unlocked"
                name="unlocked"
                :label="$t('unlocked')"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
                dense
              />
            </validation-provider>
          </v-col>
          <v-col cols="2">
            <validation-provider
              vid="deleted"
              :name="$t('deleted')"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-checkbox
                v-model="item.deleted"
                name="deleted"
                :label="$t('deleted')"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
                dense
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row v-if="!loading">
          <v-col cols="4">
            <audio
              controls
              :src="'data:' + contentType + ';base64,' + audioContent"
              v-if="item.audio"
            ></audio>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          :disabled="loading || invalid"
          :loading="loading"
          @click="updateItem"
          text
        >
          {{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </validation-observer>
  </v-card>
</template>

<script>
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";

export default {
  name: "VoiceMail",
  mixins: [showErrors, isMobile],
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    item: {},
    itemId: null,
    productId: null,
    numberId: null,
    loadingItem: false,
    updatingItem: false,
  }),
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value) {
          this.productId = value.product_id;
          this.numberId = value.number_id;
          this.itemId = value.id;
          this.getItem();
        }
      },
    },
  },
  computed: {
    loading() {
      return this.loadingItem || this.updatingItem;
    },
    contentType() {
      return this.item.audio ? this.item.audio.content_type : null;
    },
    audioContent() {
      return this.item.audio ? this.item.audio.content : null;
    },
  },
  methods: {
    getItem() {
      var that = this;
      that.loadingItem = true;
      this.$http
        .get(
          "services/voip/" +
            this.productId +
            "/numbers/" +
            this.numberId +
            "/voice-mails/" +
            this.itemId
        )
        .then((response) => {
          that.item = response.data;
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loadingItem = false;
        });
    },
    updateItem() {
      var that = this;
      this.$http
        .patch(
          "services/voip/" +
            this.productId +
            "/numbers/" +
            this.numberId +
            "/voice-mails/" +
            this.itemId,
          {
            name: this.item.name,
            saved: this.item.saved,
            static: this.item.static,
            greeting: this.item.greeting,
            music_on_hold: this.item.music_on_hold,
            unlocked: this.item.unlocked,
            deleted: this.item.deleted,
          }
        )
        .then((response) => {
          that.item = response.data;
          that.$emit("update", that.item);
          that.getItem();
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.updatingItem = false;
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "name": "Name",
    "number": "Number",
    "time start": "Time start",
    "time played": "Time played",
    "duration": "Duration",
    "saved": "Saved",
    "static": "Static",
    "greeting": "Greeting",
    "music on hold": "Music on hold",
    "unlocked": "Unlocked",
    "deleted": "Deleted",
    "save": "Save",
    "audio upload": "Audio upload"
  },
  "de": {
    "name": "Name",
    "number": "Number",
    "time start": "Time start",
    "time played": "Time played",
    "duration": "Duration",
    "saved": "Saved",
    "static": "Static",
    "greeting": "Greeting",
    "music on hold": "Music on hold",
    "unlocked": "Unlocked",
    "deleted": "Deleted",
    "save": "Speichern",
    "audio upload": "Audio upload"
  }
}
</i18n>
