<template>
  <v-card disabled flat>
    <v-simple-table dense>
      <tbody>
        <tr>
          <th>{{ $t("national number") }}</th>
          <td>{{ item.national_number }}</td>
        </tr>
        <tr>
          <th>{{ $t("international number") }}</th>
          <td>{{ item.number }}</td>
        </tr>
        <tr>
          <th>{{ $t("display name") }}</th>
          <td>{{ item.display_name ? item.display_name : "-" }}</td>
        </tr>
        <tr v-if="item.language">
          <th>{{ $t("language") }}</th>
          <td>{{ item.language }}</td>
        </tr>
        <tr>
          <th>{{ $t("is main") }}</th>
          <td>
            <boolean-value :value="item.is_main" />
          </td>
        </tr>
        <tr v-if="item.signaling_only">
          <th>{{ $t("signaling only") }}</th>
          <td>
            <boolean-value :value="item.signaling_only" color />
          </td>
        </tr>
        <tr>
          <th>{{ $t("premium number") }}</th>
          <td>
            <boolean-value :value="item.premium_number" />
          </td>
        </tr>
        <tr v-if="item.premium_number_class">
          <th>{{ $t("premium number class") }}</th>
          <td>{{ item.premium_number_class }}</td>
        </tr>
        <tr>
          <th>{{ $t("block size") }}</th>
          <td>{{ item.block_size }}</td>
        </tr>
      </tbody>
    </v-simple-table>

    <v-card-title>{{ $t("top stop") }}</v-card-title>
    <top-stop :value="item.top_stop || {}" />

    <v-card-title>{{ $t("emergency contact") }}</v-card-title>
    <emergency-contact :value="item.emergency_contact || {}" />

    <template>
      <v-card-title>{{ $t("SIP registrations") }}</v-card-title>
      <v-card
        v-for="(reg, key) in item.sip_registrations"
        :key="key"
        disabled
        flat
      >
        <sip-registration :value="reg" />
      </v-card>
      <v-card v-if="!item.sip_registrations.length > 0" disabled flat>
        <v-card-text>
          {{ $t("no SIP registrations") }}
        </v-card-text>
      </v-card>
    </template>
  </v-card>
</template>

<script>
import formatDate from "@/utils/mixins/formatDate";
import formatMoney from "@/utils/mixins/formatMoney";
import formatNumber from "@/utils/mixins/formatNumber";
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";
import BooleanValue from "@/components/basics/BooleanValue";
import TopStop from "@/components/services/voip/TopStop";
import CallForward from "@/components/services/voip/CallForward";
import EmergencyContact from "@/components/services/voip/EmergencyContact";
import SipRegistration from "@/components/services/voip/SipRegistration";

export default {
  name: "Number",
  mixins: [formatDate, formatMoney, formatNumber, showErrors, isMobile],
  components: {
    BooleanValue,
    TopStop,
    CallForward,
    EmergencyContact,
    SipRegistration,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    item: null,
  }),
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value) {
          this.item = { ...value };
        }
      },
    },
  },
};
</script>

<i18n>
{
  "en": {
    "national number": "National number",
    "international number": "International number",
    "display name": "Display name",
    "language": "Language",
    "is main": "Is main",
    "signaling only": "Signaling only",
    "premium number": "Premium number",
    "premium number class": "Premium number class",
    "block size": "Block size",
    "top stop": "Top stop",
    "emergency contact": "Emergency contact",
    "SIP registrations": "SIP registrations",
    "no SIP registrations": "No SIP registrations"
  },
  "de": {
    "national number": "Nationale Rufnummer",
    "international number": "Internationale Rufnummer",
    "display name": "Anzeigename",
    "language": "Sprache",
    "is main": "Ist Hauptnummer",
    "signaling only": "nur Signalisierung",
    "premium number": "Premiumnummer",
    "premium number class": "Premiumnummernklasse",
    "block size": "Blockgröße",
    "top stop": "Top Stop",
    "emergency contact": "Notfallkontakt",
    "SIP registrations": "SIP Registrierungen",
    "no SIP registrations": "Keine SIP Registrierungen"
  }
}
</i18n>
