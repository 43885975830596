<template>
  <v-card class="mb-4" outlined>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>{{ $t("numbers") }}</v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-card-text>
      <data-table-extended
        :headers="headers"
        :items="numbers"
        :mobile-breakpoint="mbreakpoint"
        sort-by="national_number"
        sort-desc
        :expanded="expanded"
        show-expand
        :hide-default-footer="numbers.length <= 15"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [15, 50, 100],
        }"
      >
        <template v-slot:item.display_name="{ item }">
          {{ item.display_name ? item.display_name : "-" }}
        </template>
        <template v-slot:item.start_date="{ item }">
          {{ item.start_date ? formatDate(item.start_date) : "-" }}
        </template>
        <template v-slot:item.end_date="{ item }">
          {{ item.end_date ? formatDate(item.end_date) : "-" }}
        </template>
        <template v-slot:item.is_main="{ item }">
          <boolean-value v-model="item.is_main" />
        </template>
        <template v-slot:item.premium_number="{ item }">
          <boolean-value v-model="item.premium_number" />
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="py-2">
            <number v-model="item" />
          </td>
        </template>
      </data-table-extended>
    </v-card-text>
  </v-card>
</template>

<script>
import formatDate from "@/utils/mixins/formatDate";
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";
import DataTableExtended from "@/components/basics/DataTableExtended";
import BooleanValue from "@/components/basics/BooleanValue";
import Number from "@/components/services/voip/Number";

export default {
  name: "Numbers",
  mixins: [formatDate, showErrors, isMobile],
  components: {
    DataTableExtended,
    BooleanValue,
    Number,
  },
  props: {
    numbers: {
      type: Array,
      default: () => [],
    },
    productId: {
      validator: (prop) => typeof prop === "string" || typeof prop === "number",
    },
  },
  data: () => ({
    expanded: [],
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$i18n.t("display name"),
          value: "display_name",
          align: "left",
        },
        {
          text: this.$i18n.t("number"),
          value: "national_number",
          align: "right",
        },
        {
          text: this.$i18n.t("is main"),
          value: "is_main",
        },
        {
          text: this.$i18n.t("premium number"),
          value: "premium_number",
        },
        {
          text: this.$i18n.t("start date"),
          value: "start_date",
          align: "left",
        },
        {
          text: this.$i18n.t("end date"),
          value: "end_date",
          align: "left",
        },
        { text: "", value: "data-table-expand" },
      ];
    },
  },
};
</script>

<i18n>
{
  "en": {
   "numbers": "Numbers",
   "display name": "Display name",
   "number": "Number",
   "is main": "Is main",
   "premium number": "Premium number",
   "start date": "Start date",
   "end date": "End date"
  },
  "de": {
   "numbers": "Rufnummern",
   "display name": "Anzeigename",
   "number": "Rufnummer",
   "is main": "Ist Hauptnummer",
   "premium number": "Premium Nummer",
   "start date": "Startdatum",
   "end date": "Enddatum"
  }
}
</i18n>
