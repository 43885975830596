var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"my-4",attrs:{"outlined":"","loading":_vm.loading}},[_c('v-toolbar',{attrs:{"color":"titleBar","dark":"","flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t("VoIP subscription"))+" ")]),_c('v-spacer'),(_vm.item != null)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.loading,"loading":_vm.loading,"icon":""},on:{"click":function($event){return _vm.getItem()}}},on),[_c('v-icon',[_vm._v("mdi-reload")])],1)]}}],null,false,3168186048)},[_c('span',[_vm._v(_vm._s(_vm.$t("reload")))])]):_vm._e(),(_vm.item != null && _vm.item.product_id)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({
              name: 'product-details',
              params: { productId: _vm.productId },
            })}}},on),[_c('v-icon',[_vm._v("mdi-text-box")])],1)]}}],null,false,1160111025)},[_c('span',[_vm._v(_vm._s(_vm.$t("jump to product")))])]):_vm._e()],1),_c('v-progress-linear',{attrs:{"active":_vm.loading}}),_c('v-card-text',[(_vm.item && _vm.item.subscription)?_c('v-card',{staticClass:"mb-4",attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"color":"titleBar","dark":"","flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t("subscription"))+" "+_vm._s(_vm.item ? _vm.item.product_name : ""))]),_c('v-spacer')],1),_c('v-simple-table',{staticClass:"ma-4",attrs:{"dense":""}},[_c('tbody',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("subscription number")))]),_c('td',[_vm._v(_vm._s(_vm.item.subscription.id))])]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("type")))]),_c('td',[_vm._v(_vm._s(_vm.item.subscription.type || "-"))])]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("numbers")))]),_c('td',[_vm._v(_vm._s(_vm.numbersStr || "-"))])]),(!_vm.isVPBX)?_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("SIP username")))]),_c('td',[_vm._v(_vm._s(_vm.item.subscription.username || "-"))])]):_vm._e(),(!_vm.isVPBX)?_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("SIP password")))]),_c('td',[_vm._v(_vm._s(_vm.item.subscription.password || "-"))])]):_vm._e(),(!_vm.isVPBX)?_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("SIP server")))]),_c('td',[_vm._v("sip01.iway.ch")])]):_vm._e(),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("start date")))]),_c('td',[_vm._v(" "+_vm._s(_vm.item.subscription.start_date ? _vm.formatDate(_vm.item.subscription.start_date) : "-")+" ")])]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("end date")))]),_c('td',[_vm._v(" "+_vm._s(_vm.item.subscription.end_date ? _vm.formatDate(_vm.item.subscription.end_date) : "-")+" ")])])])])],1):_vm._e(),_c('numbers',{attrs:{"numbers":_vm.numbers}}),(
        _vm.item &&
        _vm.item.subscription &&
        (_vm.item.subscription.teams_name != null ||
          _vm.item.subscription.teams_value != null)
      )?_c('v-card',{staticClass:"mb-4",attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"color":"titleBar","dark":"","flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("MS teams")))]),_c('v-spacer')],1),_c('v-simple-table',{staticClass:"pb-2 mt-4",attrs:{"dense":""}},[_c('tbody',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("teams name")))]),_c('td',[_vm._v(_vm._s(_vm.item.subscription.teams_name || "-"))])]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("teams value")))]),_c('td',[_vm._v(_vm._s(_vm.item.subscription.teams_value || "-"))])])])])],1):_vm._e(),(_vm.isVPBX)?_c('v-card',{staticClass:"mb-4",attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"color":"titleBar","dark":"","flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("vpbx")))]),_c('v-spacer')],1),_c('v-simple-table',{staticClass:"pb-2 mt-4",attrs:{"dense":""}},[_c('tbody',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("name")))]),_c('td',[_c('a',{attrs:{"href":'https://' +
                  (_vm.item.subscription.vpbx_name || '') +
                  '.' +
                  (_vm.item.subscription.vpbx_domain
                    ? _vm.item.subscription.vpbx_domain.domain
                    : '')}},[_vm._v(" "+_vm._s((_vm.item.subscription.vpbx_name || "") + "." + (_vm.item.subscription.vpbx_domain ? _vm.item.subscription.vpbx_domain.domain : ""))+" ")])])]),(_vm.item.subscription.vpbx_config)?[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("user")))]),_c('td',[_c('pre',[_vm._v(_vm._s(_vm.item.subscription.vpbx_config.user))])])]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("password")))]),_c('td',[_c('pre',[_vm._v(_vm._s(_vm.item.subscription.vpbx_config.password))])])])]:_vm._e()],2)])],1):_vm._e(),(_vm.item && _vm.item.subscription && _vm.item.subscription.top_stop)?_c('v-card',{staticClass:"mb-4",attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"color":"titleBar","dark":"","flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("top stop")))]),_c('v-spacer')],1),_c('top-stop',{model:{value:(_vm.item.subscription.top_stop),callback:function ($$v) {_vm.$set(_vm.item.subscription, "top_stop", $$v)},expression:"item.subscription.top_stop"}})],1):_vm._e(),_c('voice-mails',{attrs:{"product-id":_vm.productId,"numbers":_vm.numbers,"config":{}}}),_c('cdrs',{attrs:{"product-id":_vm.productId}}),_c('call-forwards',{attrs:{"product-id":_vm.productId}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }