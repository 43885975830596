<template>
  <v-card class="my-4" outlined :loading="loading">
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>
        {{ $t("VoIP subscription") }}
      </v-toolbar-title>
      <v-spacer />

      <v-tooltip top v-if="item != null">
        <template v-slot:activator="{ on }">
          <v-btn
            :disabled="loading"
            :loading="loading"
            v-on="on"
            @click="getItem()"
            icon
          >
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("reload") }}</span>
      </v-tooltip>

      <v-tooltip top v-if="item != null && item.product_id">
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            @click="
              $router.push({
                name: 'product-details',
                params: { productId: productId },
              })
            "
            icon
          >
            <v-icon>mdi-text-box</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("jump to product") }}</span>
      </v-tooltip>
    </v-toolbar>

    <v-progress-linear :active="loading" />

    <v-card-text>
      <!-- subscription -->
      <v-card v-if="item && item.subscription" class="mb-4" outlined>
        <v-toolbar color="titleBar" dark flat dense>
          <v-toolbar-title>
            {{ $t("subscription") }}
            {{ item ? item.product_name : "" }}</v-toolbar-title
          >
          <v-spacer />
        </v-toolbar>
        <v-simple-table dense class="ma-4">
          <tbody>
            <tr>
              <th>{{ $t("subscription number") }}</th>
              <td>{{ item.subscription.id }}</td>
            </tr>
            <tr>
              <th>{{ $t("type") }}</th>
              <td>{{ item.subscription.type || "-" }}</td>
            </tr>
            <tr>
              <th>{{ $t("numbers") }}</th>
              <td>{{ numbersStr || "-" }}</td>
            </tr>
            <tr v-if="!isVPBX">
              <th>{{ $t("SIP username") }}</th>
              <td>{{ item.subscription.username || "-" }}</td>
            </tr>
            <tr v-if="!isVPBX">
              <th>{{ $t("SIP password") }}</th>
              <td>{{ item.subscription.password || "-" }}</td>
            </tr>
            <tr v-if="!isVPBX">
              <th>{{ $t("SIP server") }}</th>
              <td>sip01.iway.ch</td>
            </tr>
            <tr>
              <th>{{ $t("start date") }}</th>
              <td>
                {{
                  item.subscription.start_date
                    ? formatDate(item.subscription.start_date)
                    : "-"
                }}
              </td>
            </tr>
            <tr>
              <th>{{ $t("end date") }}</th>
              <td>
                {{
                  item.subscription.end_date
                    ? formatDate(item.subscription.end_date)
                    : "-"
                }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>

      <!-- numbers -->
      <numbers :numbers="numbers" />

      <!-- MS Teams -->
      <v-card
        v-if="
          item &&
          item.subscription &&
          (item.subscription.teams_name != null ||
            item.subscription.teams_value != null)
        "
        class="mb-4"
        outlined
      >
        <v-toolbar color="titleBar" dark flat dense>
          <v-toolbar-title>{{ $t("MS teams") }}</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-simple-table dense class="pb-2 mt-4">
          <tbody>
            <tr>
              <th>{{ $t("teams name") }}</th>
              <td>{{ item.subscription.teams_name || "-" }}</td>
            </tr>

            <tr>
              <th>{{ $t("teams value") }}</th>
              <td>{{ item.subscription.teams_value || "-" }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>

      <!-- VPBX -->
      <v-card v-if="isVPBX" class="mb-4" outlined>
        <v-toolbar color="titleBar" dark flat dense>
          <v-toolbar-title>{{ $t("vpbx") }}</v-toolbar-title>
          <v-spacer />
        </v-toolbar>

        <v-simple-table dense class="pb-2 mt-4">
          <tbody>
            <tr>
              <th>{{ $t("name") }}</th>
              <td>
                <a
                  :href="
                    'https://' +
                    (item.subscription.vpbx_name || '') +
                    '.' +
                    (item.subscription.vpbx_domain
                      ? item.subscription.vpbx_domain.domain
                      : '')
                  "
                >
                  {{
                    (item.subscription.vpbx_name || "") +
                    "." +
                    (item.subscription.vpbx_domain
                      ? item.subscription.vpbx_domain.domain
                      : "")
                  }}
                </a>
              </td>
            </tr>

            <template v-if="item.subscription.vpbx_config">
              <tr>
                <th>{{ $t("user") }}</th>
                <td>
                  <pre>{{ item.subscription.vpbx_config.user }}</pre>
                </td>
              </tr>
              <tr>
                <th>{{ $t("password") }}</th>
                <td>
                  <pre>{{ item.subscription.vpbx_config.password }}</pre>
                </td>
              </tr>
            </template>
          </tbody>
        </v-simple-table>
      </v-card>

      <!-- top stop -->
      <v-card
        v-if="item && item.subscription && item.subscription.top_stop"
        class="mb-4"
        outlined
      >
        <v-toolbar color="titleBar" dark flat dense>
          <v-toolbar-title>{{ $t("top stop") }}</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <top-stop v-model="item.subscription.top_stop" />
      </v-card>

      <!-- voice mails -->
      <voice-mails :product-id="productId" :numbers="numbers" :config="{}" />

      <!-- CDRs -->
      <cdrs :product-id="productId" />

      <!-- call forwards -->
      <call-forwards :product-id="productId" />
    </v-card-text>
  </v-card>
</template>

<script>
import formatDate from "@/utils/mixins/formatDate";
import formatMoney from "@/utils/mixins/formatMoney";
import formatNumber from "@/utils/mixins/formatNumber";
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";
import BooleanValue from "@/components/basics/BooleanValue";
import TopStop from "@/components/services/voip/TopStop";
import CallForwards from "@/components/services/voip/CallForwards";
import EmergencyContact from "@/components/services/voip/EmergencyContact";
import Number from "@/components/services/voip/Number";
import VoiceMails from "@/components/services/voip/VoiceMails";
import Cdrs from "@/components/services/voip/Cdrs";
import Numbers from "@/components/services/voip/Numbers";

export default {
  name: "TelephonyVoipDetails",
  mixins: [formatDate, formatMoney, formatNumber, showErrors, isMobile],
  components: {
    BooleanValue,
    TopStop,
    CallForwards,
    EmergencyContact,
    Number,
    VoiceMails,
    Cdrs,
    Numbers,
  },
  props: {
    productId: {
      validator: (prop) => typeof prop === "string" || typeof prop === "number",
    },
  },
  data: () => ({
    item: null,
    loadingItem: false,
    loadingNumbers: false,
    loadingCallForwards: false,
    loadingVoiceMailCount: 0,
    loadingCdrs: false,
    numbers: [],
    voiceMails: [],
    callForwards: [],
    cdrs: [],
  }),
  watch: {
    productId: {
      immediate: true,
      handler(value) {
        if (value) {
          this.getItem();
          this.getNumbers();
          this.getCallForwards();
          this.getCdrs();
        }
      },
    },
  },
  computed: {
    loading() {
      return (
        this.loadingItem || this.loadingNumbers || this.loadingCallForwards
      );
    },
    isVPBX() {
      return (
        this.item && this.item.subscription && this.item.subscription.vpbx_name
      );
    },
    numbersStr() {
      return this.numbers.map((number) => number.national_number).join(", ");
    },
  },
  methods: {
    getItem() {
      var that = this;
      that.loadingItem = true;
      this.$http
        .get("services/voip/" + this.productId)
        .then((response) => {
          that.item = response.data;
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loadingItem = false;
        });
    },
    getNumbers() {
      var that = this;
      that.loadingNumbers = true;
      this.$http
        .get("services/voip/" + this.productId + "/numbers")
        .then((response) => {
          that.numbers = response.data;
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loadingNumbers = false;
        });
    },
    getCallForwards() {
      var that = this;
      that.loadingCallForwards = true;
      this.$http
        .get("services/voip/" + this.productId + "/call-forwards")
        .then((response) => {
          that.callForwards = response.data.map(function (cf) {
            return {
              ...cf,
              product_id: that.productId,
            };
          });
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loadingCallForwards = false;
        });
    },
    getCdrs() {
      var that = this;
      that.loadingCdrs = true;
      this.$http
        .get("services/voip/" + this.productId + "/cdrs")
        .then((response) => {
          that.cdrs = response.data;
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loadingCdrs = false;
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "VoIP details": "VoIP Details",
    "VoIP subscription": "VoIP Subscription",
    "reload": "reload",
    "jump to product": "jump to product",
    "subscription": "Subscription",
    "subscription number": "Subscription number",
    "customer reference": "Customer reference",
    "type": "Type",
    "SIP username": "SIP username",
    "SIP password": "SIP password",
    "SIP server": "SIP server",
    "start date": "Start date",
    "end date": "End date",
    "MS teams": "MS teams",
    "teams name": "Teams name",
    "teams value": "Teams value",
    "top stop": "Top stop",
    "vpbx": "VPBX",
    "name": "name",
    "max CTI": "max. CTI",
    "max user": "max. user",
    "priority": "Priority",
    "call forwards": "Call forwards",
    "numbers": "Numbers",
    "user": "User",
    "password": "Password"
  },
  "de": {
    "VoIP details": "VoIP Details",
    "VoIP subscription": "VoIP Abonnement",
    "reload": "aktualisieren",
    "jump to product": "zum Produkt wechseln",
    "subscription": "Abonnement",
    "subscription number": "Abonnement Nummer",
    "customer reference": "Kundenreferenz",
    "type": "Typ",
    "SIP username": "SIP Username",
    "SIP password": "SIP Passwort",
    "SIP server": "SIP Server",
    "start date": "Startdatum",
    "end date": "Enddatum",
    "MS teams": "MS Teams",
    "teams name": "Teams Name",
    "teams value": "Teams Wert",
    "top stop": "Top Stop",
    "vpbx": "VPBX",
    "name": "Name",
    "max CTI": "max. CTI",
    "max user": "max. User",
    "priority": "Priorität",
    "call forwards": "Anrufweiterleitung",
    "numbers": "Rufnummern",
    "user": "Benutzer",
    "password": "Passwort"
  }
}
</i18n>
