var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mb-4",attrs:{"loading":_vm.loading,"outlined":""}},[_c('v-toolbar',{attrs:{"color":"titleBar","dark":"","flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("call forwards")))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.loading,"loading":_vm.loading,"icon":""},on:{"click":function($event){_vm.expanded = [];
            _vm.showAddCallForward = !_vm.showAddCallForward;}}},on),[_c('v-icon',[_vm._v("mdi-playlist-plus")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("add new call forward")))])])],1),_c('v-card-text',[(_vm.showAddCallForward)?_c('call-forward',{attrs:{"product-id":_vm.productId,"empty-call-forward":_vm.emptyCallForward},on:{"cancel":function($event){_vm.showAddCallForward = false;
        _vm.newItem = { ..._vm.emptyCallForward };},"created":function($event){_vm.getItems();
        _vm.expanded = [];
        _vm.showAddCallForward = false;
        _vm.newItem = { ..._vm.emptyCallForward };}},model:{value:(_vm.newItem),callback:function ($$v) {_vm.newItem=$$v},expression:"newItem"}}):_c('data-table-extended',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"mobile-breakpoint":_vm.mbreakpoint,"sort-by":"id","sort-desc":"","expanded":_vm.expanded,"show-expand":"","hide-default-footer":_vm.items.length <= 15,"footer-props":{
        showFirstLastPage: true,
        itemsPerPageOptions: [15, 50],
      }},scopedSlots:_vm._u([{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{staticClass:"py-2",attrs:{"colspan":headers.length}},[_c('call-forward',{attrs:{"product-id":_vm.productId,"empty-call-forward":_vm.emptyCallForward},on:{"cancel":function($event){_vm.expanded = []},"deleted":function($event){_vm.getItems();
              _vm.expanded = [];},"update":function($event){return _vm.getItems()}},model:{value:(item),callback:function ($$v) {item=$$v},expression:"item"}})],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }